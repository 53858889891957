export const Tuples = [
    [
        {
            "words": [
                "उकळणे",
                " पेय",
                " दार्जिलिंग",
                " पाने "
            ],
            "theme": " चहा",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "टेकडी",
                " मंदिर",
                " पायऱ्या",
                " पुणे"
            ],
            "theme": "पुणे स्थित  पर्वती टेकडी संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "भरती",
                " लाटा",
                " गाज",
                " पुळण "
            ],
            "theme": " समुद्र",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मृगजळ",
                " उंट",
                " निवडुंग",
                " उष्ण "
            ],
            "theme": " वाळवंट ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "कमला",
                " शांतता",
                " सखाराम",
                " कोतवाल"
            ],
            "theme": "विजय तेंडुलकर यांच्या नाटकांच्या  नावातील शब्द",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "भांगडा",
                " गरबा",
                " बिहू",
                " घूमर "
            ],
            "theme": " विविध नृत्य प्रकार",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "इंदिरा संत",
                " शांता शेळके",
                " कुसुमाग्रज",
                " केशवसुत"
            ],
            "theme": " प्रसिद्ध मराठी कवी/कवयित्री",
            "sharedBy": "शब्दबंध टीम",
            "difficulty": 0
        },
        {
            "words": [
                "वि. स. खांडेकर",
                " शिवाजी सावंत",
                " रणजीत देसाई",
                " ना. सी. फडके"
            ],
            "theme": " प्रसिद्ध मराठी कादंबरीकार",
            "sharedBy": "शब्दबंध टीम",
            "difficulty": 1
        },
        {
            "words": [
                "अण्णासाहेब किर्लोस्कर",
                " मधुसूदन कालेलकर",
                " वसंत कानेटकर",
                " विद्याधर गोखले"
            ],
            "theme": " प्रसिद्ध मराठी नाटककार",
            "sharedBy": "शब्दबंध टीम",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "इथिओपिया",
                "बरंच काही",
                "चिकोरी",
                "अरॅबिका"
            ],
            "theme": "कॉफी - त्याचे जन्मस्थान, शास्त्रीय नाव, मिश्रण, चित्रपट इत्यादी",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "इराणी",
                " बासुंदी",
                "तंदुरी",
                " गवती"
            ],
            "theme": " चहाचे प्रकार ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "चहा",
                "कागद",
                "दारू",
                "नूडल्स/शेवया"
            ],
            "theme": "चीनचे प्राचीन शोध",
            "sharedBy": "शब्दबंध टीम",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कादंबरी",
                " नाटक",
                " कविता",
                " चरित्र"
            ],
            "theme": "वाङ्मयीन प्रकार  ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "बुद्धिबळ",
                " संगीत",
                " ओरिगामी",
                " चित्रकला "
            ],
            "theme": " छंद",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "⁠बाग",
                " पुणे",
                " गणपती",
                " तळे"
            ],
            "theme": "पुणे स्थित सारसबाग संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सर्कस",
                " फौजी",
                " उम्मीद",
                " दूसरा केवल"
            ],
            "theme": "शाहरुख खान यांच्या दूरदर्शनवरील मालिका ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "पैंजण",
                " ढोलकी",
                " बैठक",
                " नटरंग "
            ],
            "theme": " लावणीशी संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "आकाश",
                " खंड्या",
                " गोकर्ण",
                " नीलम"
            ],
            "theme": "निळ्या गोष्टी ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मैदान ",
                " हार ",
                "जीत",
                " पंच "
            ],
            "theme": " मैदानी खेळ ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "उकळणे",
                "टॅनीन",
                " दार्जिलिंग",
                " पाने "
            ],
            "theme": " चहा",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "भरती",
                " लाटा",
                " गाज",
                " पुळण "
            ],
            "theme": " समुद्र",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "गाठ",
                " राखी",
                " तंबू ",
                " फास"
            ],
            "theme": "दोरी/दोरखंड इत्यादींशी संबंधित ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "लोकल",
                " डबेवाले",
                " व्यापार",
                " बंदर"
            ],
            "theme": "मुंबई",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "मशाल",
                "इंजिन",
                "कमळ",
                "घड्याळ"
            ],
            "theme": "महाराष्ट्रातील पक्षांची निवडणूक चिन्हे",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कांगारू",
                " कोआला",
                " उलुरु ",
                " ब्रॅडमन "
            ],
            "theme": " ऑस्ट्रेलिया",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "ज्योत",
                " वाढदिवस",
                " दिवाळी",
                " मेण"
            ],
            "theme": " मेणबत्तीशी संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "नंदी",
                "रेडा",
                "उंदीर",
                "गरुड"
            ],
            "theme": "काही देवांची वाहने ",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मुघल",
                " गड ",
                " मराठा",
                " राज्याभिषेक "
            ],
            "theme": " ​​शिवाजी महाराज ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "गाजर",
                " आले",
                " हळद",
                " वसाबी "
            ],
            "theme": " जमिनीखालच्या खाण्यायोग्य वनस्पती ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "कोन",
                " स्कूप",
                " दूध",
                " व्हॅनिला "
            ],
            "theme": " आईस्क्रीम ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "काळजी",
                "विश्रांती",
                "झेप",
                "श्वास"
            ],
            "theme": "ज्या गोष्टी घेता येतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "चित्रं",
                "झोप",
                "खोडी",
                "पाणी"
            ],
            "theme": "ज्या गोष्टी काढता येतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "शोध",
                "विरजण",
                "झाड",
                "आग"
            ],
            "theme": "ज्या गोष्टी लावता येतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कॅडेट्स",
                " शिस्त",
                " परेड",
                " शौर्य"
            ],
            "theme": " भारतीय संरक्षण प्रबोधिनी (NDA) ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "रक्त",
                " जास्वंद",
                " माणिक",
                " सफरचंद"
            ],
            "theme": "लाल रंगाच्या वस्तू",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "भांगडा",
                " सलवारखमीज",
                " गहू",
                " हॉकी"
            ],
            "theme": " पंजाबशी संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "ढाल",
                "करंडक",
                "पदक",
                "चक्र"
            ],
            "theme": "पारितोषिके",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "मेण",
                "आइस्क्रीम",
                "मीठ",
                "हृदय"
            ],
            "theme": "विरघळणार्या / पाघळणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "समुद्र",
                "पोफळी",
                "मोदक",
                "आंबा"
            ],
            "theme": "कोकणातील गोष्टी ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कविता",
                " हुजूरपागा",
                "आनंदाचे झाड",
                "ऋतू हिरवा"
            ],
            "theme": "कवयित्री शांता शेळके यांच्याशी संबंधित",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "हात",
                "चौकट",
                "हुकूम",
                "विदुषक"
            ],
            "theme": "पत्ते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "गाऊन",
                "तराजू",
                "सर्वोच्च",
                "कायदा"
            ],
            "theme": "न्यायालय",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सागरा धरणी",
                "संधी",
                "जागा",
                "नोकरी"
            ],
            "theme": "ही “मिळते” :-)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "गुंड्या",
                "मोरू",
                "मैना",
                "काऊ"
            ],
            "theme": "चिमणरावांचे चऱ्हाट (पुस्तक व मालिका) मधील पात्रे ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 1
        },
        {
            "words": [
                "नदी",
                "वीज",
                "वारा",
                "रस्ता"
            ],
            "theme": "वाहणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पाणी",
                "काच",
                "हवा",
                "पोकळी"
            ],
            "theme": "पारदर्शक गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "विविध",
                "नाना",
                "अनेक",
                "निरनिराळे"
            ],
            "theme": "बरेच, खूप या अर्थी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "आवाज",
                " जत्रा",
                " अक्षर",
                "किस्त्रीम"
            ],
            "theme": "काही प्रसिद्ध दिवाळी अंक ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "भर",
                "ओढ",
                "ठेव",
                "बस"
            ],
            "theme": "नामरुपे आणि क्रियेची आज्ञार्थ रुपे",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                42.195,
                " अथेन्स",
                "केनिया",
                "कीपटूम"
            ],
            "theme": " मॅरेथॉनशी संबंधित ",
            "sharedBy": "HVN Unplugged",
            "difficulty": 1
        },
        {
            "words": [
                "दोर",
                " सुळका",
                "चढण",
                "खिळे"
            ],
            "theme": "गिर्यारोहणाशी संबंधित ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तिकीट",
                "आरक्षण",
                "स्थानक",
                "गंतव्य"
            ],
            "theme": "रेल्वे प्रवास ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "अमूल",
                "गवळी",
                "म्हैस",
                "गाय"
            ],
            "theme": "दूध देणारे :)",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 1
        },
        {
            "words": [
                "तलफ",
                "मंदी",
                "जाग",
                "शिंक"
            ],
            "theme": "ही “येते” :-)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "चुना",
                "सुपारी",
                "कात",
                "गुलकंद"
            ],
            "theme": "विडा/पान बनवण्यासाठी पदार्थ ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "लता",
                "ममता",
                "अब्बास-मस्तान",
                "गुलजार"
            ],
            "theme": "पांढरी वस्त्रे परिधान करणारे लोक ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "बहर",
                "पालवी",
                "मोड",
                "कळी"
            ],
            "theme": "वनस्पतीं वर येणार्‍या नव्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "नाणी",
                "सुटे",
                "चिल्लर",
                "आणे"
            ],
            "theme": "सुट्या पैशांचे प्रकार",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "विकत",
                "श्याम",
                "कवडी",
                "दाम"
            ],
            "theme": "\"विकत घेतला श्याम\" गाण्यातले शब्द",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "झोप",
                "ठेच",
                "बस",
                "वाट"
            ],
            "theme": "ही “लागते” :-)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "आग",
                "माल",
                "बैल",
                "घोडा"
            ],
            "theme": "गाडी प्रत्यय लावून अर्थपूर्ण ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "उचलणे",
                "जीभ",
                "लावणे",
                "टाळ्याला"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "काटा",
                " टिकटिक",
                "वेळ",
                "ठोके"
            ],
            "theme": " घड्याळ ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "बहार",
                "नंद",
                "शिव",
                "चार"
            ],
            "theme": "'सदा' हा उपसर्ग लावून अर्थपूर्ण शब्द",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "मूळ",
                "स्वाती",
                "हस्त",
                "चित्रा"
            ],
            "theme": "नक्षत्रं",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "मोहरा",
                "राणी",
                "घोडा",
                "घर"
            ],
            "theme": "बुद्धिबळाच्या पटावर सापडणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "ग्रह",
                "तारे",
                "कृष्णविवर",
                "धूमकेतू"
            ],
            "theme": "खगोलशास्त्रीय",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "म्हैस",
                "गटणे",
                "पौष्टिक",
                "वरात"
            ],
            "theme": "पु लं च्या लिखाणातून ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "चक्राकार",
                "खमंग",
                "भाजणी",
                "कुरकुरीत"
            ],
            "theme": "चकली",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "उत्प्रेक्षा",
                "यमक",
                "रूपक",
                "उपमा"
            ],
            "theme": "मराठी भाषालंकार",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "दैव",
                "देते",
                "कर्म",
                "नेते"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "विद्या",
                "भूमी",
                "कृती",
                "श्रद्धा"
            ],
            "theme": "हिंदी चित्रपटांमधील मधील काही अभिनेत्री ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "आलीया",
                "भोगासी",
                "असावे",
                "सादर "
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "विद्युत",
                "संथ",
                "वर्तुळाकार",
                "ब्राऊनिअन"
            ],
            "theme": "गती/हालचाली चे प्रकार",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "फेस",
                "तुषार",
                "खळखळाट",
                "तरंग"
            ],
            "theme": "समुद्राच्या लाटां बाबत",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कल्पवृक्ष",
                "पांचजन्य",
                "कामधेनू",
                "हलाहल"
            ],
            "theme": "समुद्र मंथनातून मिळालेल्या काही गोष्टी ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "आग",
                "रामेश्वरी",
                "बंब ",
                "सोमेश्वरी"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "बोट",
                "माड",
                "क्षितिज",
                "शिंपला"
            ],
            "theme": "समुद्र किनाऱ्यावर वर दिसणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "शह",
                "मात",
                "चाल",
                "मारणे"
            ],
            "theme": "बुद्धिबळाच्या चाली",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "दोन",
                "तीन",
                "पाच",
                "सात"
            ],
            "theme": "पहिल्या ४ मूळ संख्या",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "कागद ",
                " छपाई",
                "संपादकीय",
                "पत्रकार "
            ],
            "theme": " वर्तमान पत्र ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "देव",
                "सराफ",
                "फुले",
                "लागु"
            ],
            "theme": "जुन्या मराठी चित्रपटातल्या अभिनेत्यांची आडनावे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "स्क्रीन",
                "खिडकी",
                "भिंगं",
                "बाटली"
            ],
            "theme": "काचेच्या वस्तु",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "अंथरुण",
                "पाहणे",
                "पाय",
                "पसरणे"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "झरा",
                "झाडे",
                "पक्षी",
                "पाऊस"
            ],
            "theme": "निसर्ग ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "तिरंगा",
                "फडकणे",
                "स्तंभ",
                "वंदना"
            ],
            "theme": "झेंड्या बाबत",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "भोवरा",
                "आकाशगंगा",
                "पंखा",
                "वावटळ"
            ],
            "theme": "फिरणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "भूलभुलैया",
                "माया",
                "चकवा",
                "शोभादर्शक"
            ],
            "theme": "ज्यानी फसवणूक होते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "भक्ती",
                "प्रवचन",
                "नामजप",
                "पारायण"
            ],
            "theme": " अध्यात्म ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 1
        },
        {
            "words": [
                "मूर्ती",
                "कळस",
                "गाभारा",
                "प्रसाद"
            ],
            "theme": "देऊळ ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "उथळ",
                "पाण्याला",
                "खळखळाट",
                "फार "
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "वरूण",
                "कोसळणे",
                "मेघ",
                "वीज"
            ],
            "theme": "पाऊस",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "पाऊस",
                "वीज",
                "उल्का",
                "गारा"
            ],
            "theme": "आकाशातून पडणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "क्वार्टझ",
                "स्विस",
                "पट्टा",
                "वेळ"
            ],
            "theme": "वॉच",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "किरण",
                "सुहास",
                "चारु",
                "शीतल"
            ],
            "theme": "मराठी “यूनिसेक्स्” नावं",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "नास्तिक",
                "सरफरोशी",
                "बॉम्ब",
                "फाशी"
            ],
            "theme": "भगतसिंग यांच्या जीवनाशी संबंधित ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "गांधी",
                " राजवाडा",
                "संग्रहालय",
                "स्थानबद्ध"
            ],
            "theme": "आगा खान पॅलेस",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "उंदीर",
                "वाघ",
                "रेडा",
                "गरुड"
            ],
            "theme": "देवतांची वाहने",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "तांबड",
                "झुंजुमुंजु",
                "किलबिल",
                "भूपाळी"
            ],
            "theme": "पहाट",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "देव",
                "यम",
                "मेघ",
                "राम"
            ],
            "theme": "ज्यांना \"दूत\" जोडलेले शब्द आहेत (मेघदूत, ...)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "अशोक स्तंभ",
                "तवा",
                "ऐरण",
                "घण"
            ],
            "theme": "लोह",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "वीट",
                "मडकं",
                "देशी दारू",
                "कोळसा"
            ],
            "theme": "भट्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पद",
                "गट",
                "तट",
                "फूट"
            ],
            "theme": "राजकारणातल्या उचित अनुचित गोष्टी ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "आग",
                "पुणे",
                " काका",
                "बाजीराव"
            ],
            "theme": "पुणे स्थित शनिवार वाडा वास्तू संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "कागद",
                "रंग",
                "पाणी",
                "ब्रश"
            ],
            "theme": "चित्रकला (पेंटिंग)",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "दिल्ली",
                "गोंधळ",
                "गल्ली",
                "मुजरा"
            ],
            "theme": "राजकारण आणि त्या आधारित चित्रपटाचे नाव ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "सकाळ",
                "हिंदू",
                "एक्सप्रेस",
                "सामना"
            ],
            "theme": "वृत्तपत्रे ज्यांच्या नावाचा दुसरा अर्थही होतो",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "परदेश",
                "घर",
                "आनंद",
                "माहेर"
            ],
            "theme": "ज्याच्यात राहता येतं अश्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "दही",
                "ताडी",
                "पाव",
                "इडली"
            ],
            "theme": "आंबवलेले पदार्थ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "नंदा",
                "नाथा",
                "लखू",
                "गजा"
            ],
            "theme": "व्यक्ती आणि वल्ली' मधील काही व्यक्तिरेखा ",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "वानर",
                "अस्वल",
                "गरुड",
                "मृग"
            ],
            "theme": "रामायणातील प्राणी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "झेब्रा",
                "जिराफ",
                "चित्ता",
                "तरस"
            ],
            "theme": "आफ्रिकेतले प्राणी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "मगर",
                "कासव",
                "हिप्पो",
                "बेडूक"
            ],
            "theme": "उभयचर प्राणी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "लिफाफा",
                " आंतरदेशीय",
                " खुशाली ",
                "मुद्रांक"
            ],
            "theme": "पत्रव्यवहार संबंधी ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पाट",
                "सतरंजी",
                "खुर्ची",
                "मोडा"
            ],
            "theme": " यावर बसतात  ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "काख",
                "कळसा",
                "गाव",
                "वळसा "
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "साहेब",
                "जी",
                "राव",
                "पंत"
            ],
            "theme": "नावाला आदरार्थी जोडलेले",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "अंक",
                "फराळ",
                "उटणं",
                "लक्ष्मी"
            ],
            "theme": "दिवाळी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "नाच",
                "येणे",
                "अंगण",
                "वाकडे"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "केदार म्हसवडे",
                "आसावरी",
                "सारंग",
                "कल्याण"
            ],
            "theme": "काही राग",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तापमापक",
                "तापमान",
                " अतिवृष्टी",
                "वादळ"
            ],
            "theme": " हवामानखात्याशी संबंधित  ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "समुद्र",
                "जाळे",
                "होडी",
                "वादळ"
            ],
            "theme": "कोळ्याच्या जीवनाशी संबंधित ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 1
        },
        {
            "words": [
                "चातुर्दशी",
                "बिज",
                "बारस",
                "पूजन"
            ],
            "theme": "दिवाळीच्या दिवसांचे दुसरे शब्द",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "आधी",
                "पोट",
                "मग",
                "विठ्ठल"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "इकडे",
                "आड",
                "तिकडे",
                "विहीर"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "सूर्यफूल",
                "जवस",
                "ऑलिव्ह",
                "शेंगदाणा"
            ],
            "theme": "तेल बिया ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पक्ष",
                " मत",
                " चिन्ह",
                "सभा"
            ],
            "theme": "निवडणूक ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "⁠व्यक्ती",
                " बटाटा",
                " तुजपाशी",
                "सुंदर"
            ],
            "theme": " पु. ल. देशपांडे यांच्या पुस्तकांच्या नावातील शब्द",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "चाखणे",
                "चावणे",
                "गिळणे",
                "खाणे"
            ],
            "theme": "खाण्याशी संबंधित क्रिया",
            "sharedBy": "AI प्रणाली",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मूर्ती",
                "मिरवणूक",
                "भाद्रपद",
                "आरती"
            ],
            "theme": "गणेशोत्सव",
            "sharedBy": "HVN Unplugged",
            "difficulty": 0
        },
        {
            "words": [
                "ग्वाल्हेर",
                "जयपूर",
                "मेवाती",
                "भेंडी बाजार"
            ],
            "theme": "हिंदुस्थानी शास्त्रीय संगीतातली घराणी",
            "sharedBy": "HVN Unplugged",
            "difficulty": 1
        },
        {
            "words": [
                "नांगरणी",
                "रब्बी",
                "खरीप",
                "पेरणी"
            ],
            "theme": "कृषीशी निगडित संज्ञा",
            "sharedBy": "AI प्रणाली",
            "difficulty": 2
        }
    ]
];